import React from 'react'
import { useState } from "react";
import ImagePopup from "../ImagePopup/index.jsx";

const FileMessage = ({ file, fileType }) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {fileType === "image" ? (
        <>
          <img
            style={{
              width: "100%",
              height: "auto",
            }}
            src={file}
            className="taki-chat-file-img"
            onClick={() => setOpen(true)}
          />
          <ImagePopup open={open} handleClose={handleClose} img={file} />
        </>
      ) : fileType === "iframe" ? (
        <iframe
          src={file}
          style={{
            width: "100%",
            height: "auto",
          }}
        ></iframe>
      ) : (
        ""
      )}
    </>
  );
};

export default FileMessage;
