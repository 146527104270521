import { useRef, useState } from "react";
import { ReactComponent as RatingModalIcon } from "../../assets/icons/rating.svg";
import { ReactComponent as coloredGrinningFace } from "../../assets/icons/coloredGrinningFace.svg";
import { ReactComponent as coloredSmilingFace } from "../../assets/icons/coloredSmilingFace.svg";
import { ReactComponent as ColoredExpressionlessFace } from "../../assets/icons/coloredExpressionlessFace.svg";
import { ReactComponent as coloredAngryFace } from "../../assets/icons/coloredAngryFace.svg";
import { ReactComponent as coloredDisappointed } from "../../assets/icons/coloredDisappointed.svg";
import Emoji from "../Emoji";
import { conversationRating } from "../../features/conversation/conversationSlice";
import { useDispatch } from "react-redux";
import { emitEvent } from "../../socket/socket";

let emojisImgNames = [
  coloredAngryFace,
  coloredDisappointed,
  ColoredExpressionlessFace,
  coloredGrinningFace,
  coloredSmilingFace,
];


const RatingModal = ({ setResolved, conversationId, color }) => {
  const containerStyle = {
    "--dynamic-color": color,
  };

  const [activeIndex, setActiveIndex] = useState("");
  const textAreaRef = useRef(null);
  const dispatch = useDispatch();
  const handleEmojiClick = (index) => {
    setActiveIndex((prev) => (prev === index ? null : index));
  };
  const handleSendFeedBack = () => {
    if (activeIndex !== "" || textAreaRef.current.value.trim() !== "") {
      const userFeedBack =
        activeIndex !== "" && textAreaRef.current.value.trim() !== ""
          ? {
            userFeedback: {
              rating: Number(activeIndex) + 1,
              note: textAreaRef.current.value,
            },
          }
          : activeIndex !== ""
            ? {
              userFeedback: {
                rating: Number(activeIndex) + 1,
              },
            }
            : {
              userFeedback: {
                note: textAreaRef.current.value,
              },
            };
      dispatch(
        conversationRating({
          userFeedBack,
          conversationId,
        })
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          setResolved(true);
          emitEvent("FEEDBACK", {
            websiteID: window?.WEBSITE_ID,
            userFeedBack,
            conversationId,
          });
        }
        setResolved(false);
      });
    }
  };

  return (
    <>
      <div className="rating-modal">
        <div className="rating-modal-header">
          <div className="rating-modal-circle">
            <div className="rating-modal-circle-bg" style={color ? { ...containerStyle } : null}>
              <RatingModalIcon alt="ratingModalIcon" />
            </div>
          </div>
        </div>
        <div className="rating-modal-body">
          <div className="rating-modal-body-title">How Helpful Was This ?</div>
          <div className="rating-modal-body-emoji">
            {emojisImgNames.map((emoji, index) => (
              <Emoji
                Image={emoji}
                alt={"emojiIcon"}
                id={index}
                key={index}
                active={index === activeIndex}
                handleEmojiClick={handleEmojiClick}
              />
            ))}
          </div>
          <div className="rating-modal-body-description">
            <div className="description">Description</div>
            <textarea
              placeholder="What can you do to improve?"
              ref={textAreaRef}
              style={color ? { ...containerStyle } : null}
            ></textarea>
          </div>
          <div
            className="rating-modal-footer-button"
            style={color ? { ...containerStyle } : null}
            onClick={handleSendFeedBack}
          >
            Send Your Feedback
          </div>
        </div>
      </div>
      <div
        className="taki-chat-modal-backdrop"
        onClick={() => setResolved(false)}
      ></div>
    </>
  );
};
export default RatingModal;
