import axios from 'axios';

export default class IpService {
 static async getIpAddress() {
    try {
      const response = await axios.get('https://geoip.softylines.com/api/geolocation');
      if (response.data.ip) {
        return response.data.ip
      }
      return null;
    } catch (error) {
      console.error('Error fetching IP address:', error);
      return null;
    }
  }
}