import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
const StyledBadge = styled(Badge)(({ theme, status }) => ({
  "& .MuiBadge-badge": {
    backgroundColor:
      status === "online"
        ? "#44b700"
        : status === "away"
        ? "#ff9900"
        : "#ff0000",
    width: status === "red" ? "18px" : "12px",
    height: status === "red" ? "18px" : "12px",
    minWidth: "10px",
    right: status === "red" ? 8 : 0,
    top: status === "red" ? -17 : 25,
    border: `2px solid ${theme.palette.background.paper}`,
    borderRadius: "50%",
    overFlow: "hidden",
    padding: "0 4px",
    fontSize: "0.75rem",
    fontWeight: 600,
  },
}));

export default StyledBadge;
