export const calculateDate = (date) => {
  const today = new Date();
  const messageDate = new Date(date);
  const diff = today - messageDate;
  const diffInDays = diff / (1000 * 60 * 60 * 24);
  const diffInHours = diff / (1000 * 60 * 60);
  const diffInMinutes = diff / (1000 * 60);
  const diffInSeconds = diff / 1000;

  if (diffInDays > 1) {
    if (diffInDays>3){
      return new Date(date).toLocaleDateString() + " " +new Date(date).toLocaleTimeString().split(":")[0]+":"+new Date(date).toLocaleTimeString().split(":")[1]+" "+new Date(date).toLocaleTimeString().split(' ')[1]
    }
    return `${Math.floor(diffInDays)} day(s) ago`;
  } else if (diffInHours > 1) {
    return `${Math.floor(diffInHours)} hour(s) ago`;
  } else if (diffInMinutes > 1) {
    return `${Math.floor(diffInMinutes)} minute(s) ago`;
  } else if (diffInSeconds > 1) {
    return `${Math.floor(diffInSeconds)} second(s) ago`;
  } else {
    return `just now`;
  }
};
