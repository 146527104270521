import React from "react";
import { AppBar, Box, Toolbar, Button, Backdrop, Typography } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { saveAs } from "file-saver";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
const ImagePopup = ({ handleClose, open, img }) => {
  const saveFile = () => {
    saveAs(img);
  };
  return (
    <div>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "block",
        }}
        open={open}
        onClick={handleClose}
      >
        <Box sx={{ flexGrow: 1 }}>
          <AppBar
            position="static"
            style={{ backgroundColor: "transparent", boxShadow: "none" }}
          >
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                <ImageOutlinedIcon />
              </Typography>
              <Button onClick={saveFile} color="inherit">
                <FileDownloadOutlinedIcon />
              </Button>
            </Toolbar>
          </AppBar>
        </Box>
        <img src={img} alt="image-message" className="image-modal" />
      </Backdrop>
    </div>
  );
};
export default ImagePopup;
