export const botMessages = [
    {
        lang: 'en',
        body: "I will put you in touch with one of our advisors. Please wait a few minutes."
    },
    {
        lang: 'fr',
        body: "Je vais vous mettre en relation avec l'un de nos conseillers. Veuillez patienter quelques minutes."
    },
    {
        lang: 'ar',
        body: "سأجعلك على اتصال مع أحد مستشارينا. يرجى الانتظار بضع دقائق."
    },
    {
        lang: 'de',
        body: "Ich werde Sie mit einem unserer Berater in Kontakt bringen. Bitte warten Sie ein paar Minuten."
    },

]