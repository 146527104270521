import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { emitEvent } from "../../socket/socket";
import messageService from "./messageService.js";
import Cookies from "universal-cookie";

import { incrementUnreadMessages } from "../conversation/conversationSlice";
const initialState = {
  messages: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  getMessagesLoading: false,
  fileLoading: false,
  messagesTotalPages: null,
  getMessagesSuccess: "idle",
  message: "",
  operatorId: null,
  msg: {},
};
const cookies = new Cookies();

// CreateMessage user
export const createMessage = createAsyncThunk(
  "messages/createMessage",
  async (messageContent, thunkAPI) => {
    let fakeId = messageContent.fakeId;
    try {
      const response = await messageService.createMessage(
        Object.assign(messageContent, { fakeId: undefined })
      );
      thunkAPI.dispatch(incrementUnreadMessages());
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
        fakeId,
      });
    }
  }
);
//Add user data on connect
export const addUser = createAsyncThunk("/addUser", async (data, thunkAPI) => {
  try {
    return await messageService.addUser(data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
//Add user data on connect
export const sendMeta = createAsyncThunk("/addUser", async (data, thunkAPI) => {
  try {
    return await messageService.sendMeta(data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Seen by client
export const SeenByClient = createAsyncThunk(
  "messages/SeenByClient",
  async (data, thunkAPI) => {
    try {
      return await messageService.SeenByClient(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
        // fakeId,
      });
    }
  }
);
// create message for bot
export const createMessageByBot = createAsyncThunk(
  "messages/createMessageByBot",
  async (data, thunkAPI) => {
    try {
      return await messageService.createMessageByBot(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// create message for bot
export const createMessageByAI = createAsyncThunk(
  "messages/createMessageByAI",
  async (data, thunkAPI) => {
    try {
      return await messageService.createMessageByAI(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get question by choice
export const getQuestionByChoice = createAsyncThunk(
  "messages/getQuestionByChoice",
  async (data, thunkAPI) => {
    try {
      return await messageService.getQuestionByChoice(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get question by order
export const getQuestionByOrder = createAsyncThunk(
  "messages/getQuestionByOrder",
  async (data, thunkAPI) => {
    try {
      return await messageService.getQuestionByOrder(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const translate = createAsyncThunk(
  "messages/translate",
  async (data, thunkAPI) => {
    try {
      return await messageService.translate(data.content);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const transcribe = createAsyncThunk(
  "messages/transcribe",
  async (data, thunkAPI) => {
    try {
      return await messageService.transcribe(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const askAI = createAsyncThunk(
  "messages/askAI",
  async (data, thunkAPI) => {
    try {
      return await messageService.askAI(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Conversation Messages
export const getMessages = createAsyncThunk(
  "messages/getMessages",
  async (data, thunkAPI) => {
    try {
      return await messageService.getMessages(data.conversationId, data.page);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const messageSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = "";
      state.getMessagesLoading = false;
    },
    addMessageToState: (state, action) => {
      state.messages = [...state.messages, action?.payload];
    },
    setMessagesTotalPages: (state, action) => {
      state.messagesTotalPages = action.payload;
    },
    updateMessage: (state, action) => {
      state.messages = state.messages.map((message) => {
        if (message._id === action?.payload.message._id) {
          return action?.payload.message;
        }
        return message;
      });
    },
    deleteMessage: (state, action) => {
      state.messages = state.messages.filter(
        (message) => message._id !== action?.payload?.message?._id
      );
    },
    changeCreatedByMessagesStatus: (state, action) => {
      state.messages = state.messages.map((message) => {
        if (message.createdBy) {
          if (message.createdBy._id === action.payload.operatorId) {
            message.createdBy.availability.status = action?.payload?.status;
          }
        } else {
          message = {
            ...message,
            createdBy: { availability: { status: action?.payload?.status } },
          };
        }
        return message;
      });
    },
    seenMessageClient: (state, action) => {
      state.messages = state.messages.map((message) => {
        return message._id === action.payload._id
          ? { ...action.payload, seenByClient: true }
          : message;
      });
    },
    setMsg: (state, action) => {
      state.messages = state.messages.map((msg) =>
        msg.fakeId === action.payload.fakeId ? action.payload.msg : msg
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createMessage.pending, (state, action) => {
        if (action.meta.arg.file) {
          state.fileLoading = true;
        }
        state.isLoading = true;
      })
      .addCase(createMessage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        if (
          typeof action.payload?.newMessage?.conversation === "object" &&
          action?.payload?.newMessage?.from === "CLIENT"
        ) {
          emitEvent("MESSAGE_FROM_CLIENT", {
            webSiteId: window?.WEBSITE_ID,
            conversation: {
              ...action.payload?.newMessage?.conversation,
              lastMessage: action.payload?.newMessage,
              unread: 1,
            },
            newConversation: true,
          });
        } else {
          emitEvent("MESSAGE_FROM_CLIENT", {
            webSiteId: window?.WEBSITE_ID,
            conversation: {
              ...action.payload.conversationData,
              lastMessage: action.payload.newMessage,
              unread: action.payload.conversationData?.unread + 1,
            },
          });
        }
        state.fileLoading = false;
      })
      .addCase(createMessage.rejected, (state, action) => {
        state.isLoading = false;
        state.fileLoading = false;
        state.isError = true;
        state.errorMessage = action.payload.message;
        if (action.payload.fakeId) {
          const message = state.messages.find(
            (message) => message.fakeId === action.payload.fakeId
          );
          if (message) {
            message.isSent = false;
          }
        }
      })
      .addCase(getMessages.pending, (state) => {
        state.isLoading = true;
        state.getMessagesLoading = true;
      })
      .addCase(getMessages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getMessagesLoading = false;
        state.isSuccess = true;
        state.getMessagesSuccess = "success";
        state.messages = [...action.payload.docs, ...state.messages];
        state.messagesTotalPages = action.payload.totalPages;
      })
      .addCase(getMessages.rejected, (state, action) => {
        state.isLoading = false;
        state.getMessagesLoading = false;
        state.isError = true;
        state.getMessagesSuccess = "fail";
        state.errorMessage = action.payload;
      });
  },
});
export const {
  reset,
  addMessageToState,
  changeCreatedByMessagesStatus,
  updateMessage,
  deleteMessage,
  seenMessageClient,
  setMsg,
  setMessagesTotalPages,
} = messageSlice.actions;
export default messageSlice.reducer;
