import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import operatorService from "./operatorService.js";

const initialState = {
  operators: [],
  connectedOperators: [],
  assignedOperator: null,
  totalOperators: null,
};

// Get Conversation Messages
export const getAllOperators = createAsyncThunk(
  "operators/getAllOperators",
  async (_, thunkAPI) => {
    try {
      return await operatorService.getAllOperators();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const operatorSlice = createSlice({
  name: "operators",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },

    changeOperatorStatus: (state, action) => {
      if (action.payload.operatorId && action.payload.status) {
        const operator = state.operators.find(
          (operator) => operator._id === action.payload.operatorId
        );

        if (
          operator &&
          operator?.availability?.status !== action?.payload?.status
        ) {
          operator.availability.status = action?.payload?.status;
          if (action.payload.status === "online") {
            if (!state.connectedOperators.includes(operator)) {
              state.connectedOperators = [
                ...state.connectedOperators,
                operator,
              ];
            }
          } else if (
            action.payload.status === "offline" ||
            action.payload.status === "away"
          ) {
            state.connectedOperators = state.connectedOperators.filter(
              (operator) => operator._id !== action.payload.operatorId
            );
          }
        }
      }
    },

    changeAssignOperatorStatus: (state, action) => {
      if (state.assignedOperator.status !== action.payload) {
        if (action.payload === "offline") {
          // if assigned operator is offline, remove assigned operator from connected operators
        }
        state.assignedOperator.availability.status = action.payload;
      }
    },
    // setAssignedOperator: (state, action) => {
    //   // search a message from operator

    //   if (!state.assignedOperator) {
    //     const messageFromOperator = action.payload?.find((m) => {
    //       return m.from === "OPERATOR" ;
    //     });
    //     if (messageFromOperator) {
    //       // if message from operator is found, set assigned operator

    //       state.assignedOperator = state.operators.find(
    //         (operator) => operator._id === messageFromOperator.operator
    //       );
    //     }
    //   }
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllOperators.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllOperators.fulfilled, (state, action) => {
        if (action.payload) {
          state.connectedOperators = action.payload.docs
            .filter((operator) => operator?.availability?.status === "online")
            .sort(function () {
              return Math.random() - 0.5;
            });
          state.operators = action.payload.docs;
          state.totalOperators = action.payload?.meta?.totalDocs;
        }

        //  get only the connected operator
      })
      .addCase(getAllOperators.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const {
  reset,
  changeOperatorStatus,
  changeAssignOperatorStatus,
  // setAssignedOperator,
} = operatorSlice.actions;
export default operatorSlice.reducer;
