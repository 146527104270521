export const removeCookiesByName = (name) => {
  const cookies = document.cookie.split(";");
  const domain = "." + window.location.hostname.split(".").slice(-2).join(".");
  cookies.forEach((cookie) => {
    const [cookieName, cookieValue] = cookie.split("=");
    const trimmedCookieName = cookieName.trim();

    if (trimmedCookieName === name) {
      document.cookie = `${trimmedCookieName}=; domain=${domain}; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    }
  });
};
