import React from 'react'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Upload = ({ setGetFile, disabled, color }) => {
  const handleChange = async (event) => {
    if (
      event.target.files[0] &&
      (event.target.files[0].type.includes("pdf") ||
        event.target.files[0].type.includes("audio") ||
        event.target.files[0].type.includes("image") ||
        event.target.files[0].type.includes("video") ||
        event.target.files[0].type.includes("msword") ||
        event.target.files[0].type.includes("wordprocessingml"))
    ) {
      const fileSize = event.target.files[0].size / 1024;
      const MAX_FILE_SIZE = 5120;
      if (fileSize > MAX_FILE_SIZE) {
        console.log("File too large");
        return;
      }
      setGetFile(event.target.files[0]);
    } else {
      console.log("File not supported");
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="upload-container">
        <label
          htmlFor="inputTag"
          style={color && { backgroundColor: color }}
          className="upload-button"
        >
          <input
            id="inputTag"
            onChange={(e) => handleChange(e)}
            accept=".jpg, .png, .jpeg, .svg, .gif, .pdf"
            className="takiChatfileInput"
            type="file"
            multiple
            disabled={disabled}
            onClick={(event) => {
              event.target.value = null;
            }}
          ></input>
          +
        </label>
      </div>
    </>
  );
};

export default Upload;
