import React from 'react'
const Emoji = ({ Image, alt, id, active, handleEmojiClick }) => {
  return (
    <div
      className={
        active
          ? "rating-modal-body-emoji-itemcolored "
          : "rating-modal-body-emoji-item"
      }
      onClick={() => handleEmojiClick(id)}
    >
      <Image alt={alt} id={id} />
    </div>
  );
};

export default Emoji;
