import axios from "../../utils/axios";
import axios2 from 'axios'
import Cookies from "universal-cookie";
import { serialize } from "object-to-formdata";
import socket, { emitEvent } from "../../socket/socket";
import IpService from "../../utils/ipService";
import { removeCookiesByName } from "../../utils/removeCookie";
const cookies = new Cookies();
if (!window.WEBSITE_ID) {
  console.log("you have to use the script and add the website id");
}

// CreateMessage user
const createMessage = async (messageContent) => {
  let data;
  const { conversationData, ...message } = messageContent;

  if (message?.file) {
    data = serialize({
      ...message,
      meta: JSON.stringify(message.meta),
    });
  } else {
    data = message;
  }
  if (!cookies.get(`conversationId${window.WEBSITE_ID}`)) {
    data.newConversation = true;
    const ip = await IpService.getIpAddress();
    data.ip = ip;
  }
  let response = await axios.post(
    `/api/websites/${window.WEBSITE_ID}/messages/user`,
    data
  );
  let newMessage;
  if (
    !message.conversation ||
    response.data?.data?.msg?.includes("not found") ||
    response.data?.data?.msg?.includes("invalid value")
  ) {
    const domain =
      "." + window.location.hostname.split(".").slice(-2).join(".");
    removeCookiesByName(`conversationId${window.WEBSITE_ID}`);
    cookies.set(
      `conversationId${window.WEBSITE_ID}`,
      response.data?.data?.conversation?._id,
      {
        path: "/",
        maxAge: 31536000,
        domain: domain,
      }
    );
    newMessage = {
      content: message?.content,
      conversation: response.data?.data?.conversation,
      seenBy: [],
      _id: response.data?.data?._id,
      from: "CLIENT",
      file: response.data?.data?.file || undefined,
      filetype: response.data?.data?.filetype || undefined,
      createdAt: Date.now(),
    };
    emitEvent("CONNECT", {
      webSiteId: window.WEBSITE_ID,
      SOCKET_ID: socket?.id,
      conversationId: response?.data?.data?.conversation?._id,
    });
  } else {
    newMessage = {
      ...message,
      seenBy: [],
      _id: response.data?.data?._id,
      from: "CLIENT",
      file: response.data?.data?.file || undefined,
      filetype: response.data?.data?.filetype || undefined,
      createdAt: Date.now(),
    };
  }
  return { newMessage, conversationData };
};

// create message for bot
const createMessageByBot = async (data) => {
  if (data?.newConversation) {
    const response = await axios.post(
      `/api/websites/${window.WEBSITE_ID}/messages/conversations/bot`,
      data
    );
    return response?.data?.data
  }
  const response = await axios.post(
    `/api/websites/${window.WEBSITE_ID}/messages/conversations/${data?.conversationId}/bot`,
    { question: data?.question }
  );

  return response.data.data;
};
// create message for bot
const createMessageByAI = async (data) => {
  let convID = cookies.get(`conversationId${window.WEBSITE_ID}`);
  const response = await axios.post(
    `/api/websites/${window.WEBSITE_ID}/messages/conversations/${convID}/bot/ai`,
    data
  );

  return response.data.data;
};

// Get Conversation Messages
const getMessages = async (conversationId, page) => {
  if (conversationId) {
    const response = await axios.get(
      `/api/websites/${window.WEBSITE_ID}/messages/conversations/${conversationId}/user?page=${page}&limit=10`
    );

    if (response?.status === 200) {
      return response?.data?.data;
    }
  }
};
// Seen message by client
const SeenByClient = async (data) => {
  const response = await axios.put(
    `/api/websites/${window.WEBSITE_ID}/conversations/${data?.conversation}/messages/${data?.message}/seenByClient`,
    {
      seenByClient: true,
    }
  );

  if (response.status === 200) {
    return response.data.data;
  }
};
const addUser = async (data) => {
  await axios.post(`/api/websites/${window.WEBSITE_ID}/users`, data);
};
const sendMeta = async (data) => {
  await axios.post(
    `/api/websites/${window.WEBSITE_ID}/messages/conversations/${data?.conversation}/meta/user`,
    data
  );
};
const getQuestionByChoice = async (data) => {
  let choice = typeof data === "object" ? data?._id : data;
  const response = await axios.get(
    `/api/websites/${window.WEBSITE_ID}/questions/${choice}`
  );
  if (response.status === 200) {
    return response.data?.data;
  }
};
const getQuestionByOrder = async (data) => {
  const response = await axios.get(
    `/api/websites/${window.WEBSITE_ID}/questions/getone?order=${data?.currentOrder}`
  );
  if (response.status === 200) {
    return response.data?.data;
  }
};


const translate = async (data) => {
  const response = await axios2.post(
    `https://ai.lissene.dev/detect_language`,
    {
      text: data
    }
  );
  if (response.status === 200) {
    return response;
  }
};
const transcribe = async (data) => {
  const formData = new FormData();
  formData.append('file', data, 'recording.mp3');
  const response = await axios.post('https://ai.lissene.dev/transcribe', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  if (response.status === 200) {
    return response;
  }
};
const askAI = async (data) => {
  const response = await axios2.post(
    `https://ai.lissene.dev/get_answer`,
    {
      query: data?.text
    }
  );
  if (response.status === 200) {
    return response.data;
  }
};

const messageService = {
  getMessages,
  createMessage,
  createMessageByBot,
  SeenByClient,
  addUser,
  sendMeta,
  getQuestionByChoice,
  getQuestionByOrder,
  translate,
  askAI,
  createMessageByAI,
  transcribe
};
export default messageService;
