import { configureStore } from "@reduxjs/toolkit";
import messageReducer from "../features/message/messageSlice.js";
import operatorReducer from "../features/operator/operatorSlice.js";
import conversationReducer from "../features/conversation/conversationSlice.js";

export const store = configureStore({
  reducer: {
    messages: messageReducer,
    operators: operatorReducer,
    conversation: conversationReducer,
  },
});
