import React, { useState } from "react";
import KeyboardVoiceOutlinedIcon from "@mui/icons-material/KeyboardVoiceOutlined";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import vmsg from 'vmsg';


const recorder = new vmsg.Recorder({
  wasmURL: 'https://unpkg.com/vmsg@0.3.0/vmsg.wasm',
});
function AudioRecorder({ audioResult, color, setAudioRecordFile, setStartedRecording, setAudioResult }) {
  const [isRecording, setIsRecording] = useState(false);

  const record = async () => {
    if (isRecording) {
      try {
        const blob = await recorder.stopRecording();
        console.log(recorder);
        setAudioRecordFile(blob)
        setIsRecording(false);

      } catch (error) {
        console.error('Error recording or sending audio:', error);
      }
    } else {
      try {
        await recorder.initAudio();
        await recorder.initWorker();
        recorder.startRecording();
        setIsRecording(true);
      } catch (e) {
        console.error(e);
      }
    }
  };
  return (
    <div className="audio-record-container">
      <div className="inner-container">
        {/* <button disabled={isLoading} onClick={record}>
          {isRecording ? 'Stop' : 'Record'}
        </button> */}
        <div className="buttons">
          {!isRecording && !audioResult && (
            <button
              className="btn-record"
              style={{ color, border: `1px solid ${color}` }}
              onClick={() => {
                record()
                setStartedRecording(true)
              }}
            >
              <KeyboardVoiceOutlinedIcon style={{ marginTop: "2px" }} />
            </button>
          )}
          {isRecording && (
            <button className="btn-stop" onClick={() => {
              setStartedRecording(false)
              record()
            }}>
              <StopCircleOutlinedIcon
                style={{
                  marginTop: "2px",
                  color,
                }}
              />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default AudioRecorder;
