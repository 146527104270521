import axios from "../../utils/axios";

// Get All operators
const getAllOperators = async () => {
  const response = await axios.get(
    `/api/clients/websites/user/${window.WEBSITE_ID}/operators?page=1&limit=10`
  );
  return response.data.data;
};

const operatorService = {
  getAllOperators,
};
export default operatorService;
