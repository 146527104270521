import React from 'react'
const ToolTip = ({ text,style }) => {
  return (
    <>
      <span className="toolTip" style={style}>{text}</span>
    </>
  );
};

export default ToolTip;
