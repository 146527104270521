import React from 'react'
import { Avatar } from "@mui/material";
import ToolTip from "../ToolTip/index.jsx";

const Employee = ({ image, name, uniqueKey }) => {
  return (
    <>
      <div className="employee-wrapper" key={uniqueKey}>
        <div className="employee">
          <Avatar src={image} alt="employeeImg" />
        </div>
        <ToolTip text={name} />
      </div>
    </>
  );
};

export default Employee;
